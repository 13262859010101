:root {
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
}

.App {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.App > hr {
  border: 1px solid rgb(200, 200, 200, 0.3);
  width: 80%;
}

.logo {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
}
.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, monospace, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.logo > div:nth-child(1) {
  font-weight: bold;
  color: rgb(100, 200, 200, 0.8);
}
.logo a {
  font-size: 20px;
  color: orange;
  text-decoration: underline;
}

.btn {
  background-color: transparent;
  padding: 1rem;
  margin: 3rem;
  border-radius: 30px;
  border: 2px solid white;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;

}
.btn:hover {
  cursor: pointer;
  background-color: orange;
  border: none;
}

.button1 {
  background-color: rgb(100, 200, 200, 0.8);
  padding: 1rem;
  margin-left: 5rem;
  margin-right: 5rem;
  border-radius: 30px;
  border: 2px solid white;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.button1:hover {
  cursor: pointer;
  background-color: orange;
  border: none;
}
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
@media screen and (max-width:768px) {
  .logo{
    background: white;
    width: 100%;
    display: flex;
    flex: none;
    position: fixed;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    
  }
  
}