.Login {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg {
  display: flex;
  flex-direction: column;
  right: 20rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/src/components/Assets/cover.jpg");
  width: 70rem;
  border-radius: 10px 10px 10px 10px;
  height: 60rem;
  
}
.left1 {
  background-color: rgb(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  flex: 2 1;
  padding: 1rem;
  position: relative;
  border-radius: 10px 0px 0px 10px;
}
.right1 {
  flex: 1;
  position: relative;
  background-color: rgb(100, 200, 200, 0.8);
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 1rem;
}
.left1 > div:nth-of-type(1) {
  display: flex;
  align-content: center;
  font-size: 1rem;
}
.left1 > div:nth-of-type(2) {
  font-size: 2rem;
  font-weight: bold;
  color: rgb(100, 200, 200);
  margin-top: 4rem;
}
.left1 > div:nth-of-type(3) {
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0.5rem;
  gap: 10px;
}
.left1 > div:nth-of-type(4) {
  padding-top: 10px;
  font-size: 1rem;
  color: gray;
}
.login {
  display: flex;
  flex-direction: column;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  margin-left: 5rem;
}
.btn {
  background-color: transparent;
  padding: 1rem;
  margin: 3rem;
  border-radius: 30px;
  border: 2px solid white;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right1 > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  font-size: 2rem;
  font-weight: bold;
}
.right1 > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 6px;
  font-size: 14px;
  font-weight: lighter;
}
@media screen and (max-width:768px) {
  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    
  }
  .title{
   display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: none;
  }
.bg{
   padding: 20px;
   
}
.right1{
margin-top: 20px;
 width: 15rem;
 background: none;
 
}
.right1 > div:nth-of-type(1){

  font-size: large;
  margin-bottom: -1rem;
  margin-top: -1rem;
}



.left1 > div:nth-of-type(1){
  display: none;
 
}
.left1 > div:nth-of-type(2){
  font-size:large;
  
 
}

.left1 {
  flex: none;
width: 20rem;

/* margin-bottom: 30rem; */
  border-radius: 10px 10px 10px 10px;
}


}
